<template>
  <div class="mark-project-main-detail">
    <div class="searchCon">
      <el-row>
        <el-col :span="11" :offset="1" class="title">{{$t('mark.mark')}}</el-col>
        <el-col :span="11" class="btns">
          <img
            class="img"
            @click="upload"
            src="/img/taskCenter/labelPage/上传.png"
          />
          <img
            class="img"
            @click="addFile"
            src="/img/taskCenter/labelPage/添加文件.png"
          />
        </el-col>
        <el-col :span="1">&emsp;</el-col>
        <el-col :span="22" :offset="1" class="searchIpt">
          <el-input :placeholder="$t('mark.searchByName')" v-model="filterText">
          </el-input>
        </el-col>
        <el-col :span="1">&emsp;</el-col>
        <el-col :span="1">&emsp;</el-col>
        <!-- 分割线 -->
        <el-col :span="24" class="line"></el-col>
      </el-row>
    </div>
    <div class="container">
      <el-input
        class="showAppend-input"
        v-if="showAppend == true"
        size="mini"
        @input="changeInput"
        @blur="appendBlur"
        v-model="appendInput"
        :props="defaultProps"
        :placeholder="$t('input')"
        v-focus
      ></el-input>
      <div 
        v-loading="treeLoading"
        :element-loading-text="this.$t('loading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        class="tree-box">
        <el-tree
          ref="tree"
          :data="treeData"
          node-key="value"
          default-expand-all
          :expand-on-click-node="false"
          highlight-current
          :filter-node-method="filterNode"
          @node-click="nodeClick"
          @check="check">
          <div @mouseleave="treeNodeMouseLeave(node, data)" class="tree-node" slot-scope="{ node, data }">
            <span class="tree-node-icon">
              <img
                :width="16"
                v-if="data.type&&data.type==='Point'" 
                :src="'/img/map/mark_manager.png'" />
              <img 
                :width="16"
                v-else-if="data.type&&data.type==='LineString'" 
                :src="'/img/map/oilLine.png'" />
              <img 
                :width="16"
                v-else-if="data.type&&data.type==='Polygon'" 
                :src="'/img/map/MarkPolygon.png'" />
              <i v-else class="el-icon-folder"></i>
            </span>
            <span class="tree-node-text">{{node.label}}</span>
            <span class="tree-node-btn">
              <i 
                v-if="node.level ===1&&data.type!==0"
                @click.prevent="(e)=>{
                  editNode(node, data, e)
                }"
                class="el-icon-edit-outline"></i>
              <i 
                v-if="node.level!==1"
                @click.prevent="(e)=>{
                  flyToNode(node, data, e)
                }"
                class="el-icon-aim"></i>
              <i 
                v-if="data.type!==0"
                @click.prevent="(e)=>{
                  deleteTreeNode(node, data, e)
                }"
                :class="data.value===deleteNodeValue? 'bgc-red': ''"
                class="el-icon-delete"></i>
              <i
                @click.prevent="(e)=>{
                  showHiddenNode(node, data, e)
                }"
                :class="data.show? 'active-icon': ''"
                class="el-icon-view"></i>
              
            </span>
          </div>
        </el-tree>
      </div>
    </div>
    <el-dialog
      :title="$t('mark.upload')"
      :visible.sync="markDialogVisible"
      width="30%"
      append-to-body
      class="common-map-dialog private-mark-markpage-dialog"
      :close-on-click-modal="false"
      :before-close="handleClose">
      <span>
        <el-form 
          :model="form" 
          ref="form"
          :rules="rules"
          label-width="120px"
          class="demo-dynamic">
          <el-form-item
            prop="coordinate"
            :label="$t('map.coordinateSystem')">
            <el-input  style="width: 200px" :disabled="true" v-model="form.coordinate"></el-input>
          </el-form-item>
          <el-form-item prop="type" label="">
            <el-upload
              :multiple="false"
              class="upload-demo"
              :headers="uploadHeaders"
              ref="upload"
              name="file"
              :http-request="httpRequest"
              :on-remove="handleRemove"
              :file-list="fileList"
              :limit="1"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">{{$t('uploadText')}}</el-button>
              <div slot="tip" class="el-upload__tip">{{$t('inspection.uploadKML')}}</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="markDialogVisible = false">{{ $t('cancelText') }}</el-button>
        <el-button type="primary" @click="uploadKML">{{ $t('submitText') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('inspection.uploadInspection')"
      :visible.sync="editDialogVisible"
      width="30%"
      class="common-map-dialog private-mark-markpage-dialog"
      append-to-body
      :close-on-click-modal="false"
      :before-close="editClose">
      <span>
        <el-form 
          :model="pathForm" 
          ref="pathForm"
          label-width="120px"
          class="demo-dynamic">
          <el-form-item
            prop="coordinate"
            :label="`${$t('folderName')}:`">
            <el-input v-model="pathForm.dirName"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editClose">{{ $t('cancelText') }}</el-button>
        <el-button type="primary" :loading="saveLoading" @click="saveEdit">{{ $t('submitText') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getEmergencytaskDetail } from "@/api/emergency/index";
import {
  addTreeNode,
  addGroup,
  addLabel,
  setExpand,
  deleteTreeNode,
  getLabelTreeList,
  updateTreeNode,
  updateDirVisibility
} from "@/api/project/label";
import { getList } from '@/api/system/dept'
import axios from 'axios'
import website from '@/config/website';
import { Base64 } from 'js-base64';
import { mapGetters } from "vuex";

export default {
  name: "routePlanning",
  data() {
    return {
      // 该项目的具体信息
      detailInfo: {},
      // 搜索内容
      filterText: "",
      // 增加文件名称
      appendInput: "",
      groupCount: 0,
      showAppend: false,
      treeData: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      checked: [],
      // 双击事件
      nodeItem: {},
      isEdit: 0,
      nodeCount: 0,
      preNodeId: null,
      curNodeId: null,
      nodeTimer: null,
      newApiGroupName: "",
      deleteNodeValue: null,
      treeLoading: true,
      selecteId: null,
      markDialogVisible: false,
      uploadList: [
        {
          label: '点',
          value: 0
        },
        {
          label: '线',
          value: 1
        },
        {
          label: '面',
          value: 1
        }
      ],
      
      form: {
        coordinate: 'WGS84',
        type: 0
      },

      rules: {
        coordinate: [
          { required: true, message: this.$t('inputPlease'), trigger: 'blur' },
        ],
        type: [
          { required: true, message: this.$t('selectPlease'), trigger: 'change' }
        ]
      },

      uploadHeaders: {

      },

      loading: false,
      editDialogVisible: false,
      pathForm: {
        id: '',
        dirName: ''
      },
      saveLoading: false
    };
  },
  directives: {
    //注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function (el) {
        // 聚焦元素
        el.querySelector("input").focus();
      },
    },
  },
  computed: {
    ...mapGetters(["newFeature"]),
  },
  created() {
    // this.getDetail()
  },
  mounted() {
    this.getList(true);
    this.$EventBus.$off('left-tree-update');
    this.$EventBus.$on('left-tree-update', this.getList);
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    newFeature: function (val) {
      if (val) {
        this.addNewLabel(val);
      }
    },
  },
  methods: {
    backToList() {
      this.$router.push('/mission-center/item-manager-config');
    },

    getDetail() {
      let project = this.$route.query.projectId
      getEmergencytaskDetail({id: project}).then((res) => {
        if (res.data.code === 200) {
          this.detailInfo = res.data.data;
        }
      });
    },

    getList(isInit) {
      let projectId = this.$route.query.projectId
      if (!projectId) {
        return
      }
      const params = {
        projectId,
        dirType: 1
      };

      getLabelTreeList(params).then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data
          this.treeData = data.map((item, index )=> {
            item.label = item.dirName
            item.value = item.id
            item.index = index
            item.children = item.mapMarks.map(_item => {
              return {
                label: _item.content.properties.name,
                value: _item.id,
                show: _item.visibility,
                type: _item.content.geometry.type,
                dirId: _item.dirId,
                id: _item.id
              }
            })
            item.show = item.children.some(item=>{
              return item.show !== false
            })
            return item
          });
          this.groupCount = data.length;
          if (isInit && Array.isArray(data) && data.length > 0) {
            // 初次加载设置默认选中
            this.$nextTick(()=>{
              this.$refs.tree.setCurrentKey(this.treeData[0].id)
            })
            // 维护全局变量信息
            this.$store.dispatch("setHandlerType", {
              type: "switchWorkSpace",
              params: {
                // groupId 注册到layermanager中的layer对星际和中，并且绑定其中name
                groupId: this.treeData[0].id,
                groupName: this.treeData[0].label,
                visible: this.treeData[0].visibility
              }
            })
          } else {
            if (this.selecteId) {
              this.$nextTick(()=>{
                var key = this.$refs.tree.getCurrentKey(this.selecteId)
                if (key) {
                  this.$refs.tree.setCurrentKey(key)
                } else {
                  this.$refs.tree.setCurrentKey(this.treeData[0].id)
                }
              })
            }
          }
        }
      }).finally(()=>{
        this.treeLoading = false
      })
    },

    nodeClick(data, node, prop) {
      // 记录当前选中的树节点
        this.selecteId = node.data.value
        if (node.level === 1) {
            this.$store.dispatch("setHandlerType", {
              type: "switchWorkSpace",
              params: {
                groupId: data.id,
                groupName: data.label,
                visible: prop.node.checked,
              },
            })
        } else {
          let _node = this.findNodeByLevel(node, 1)
          this.$store.dispatch("setHandlerType", {
            type: "switchWorkSpace",
            params: {
              groupId: _node.data.id,
              groupName: _node.data.label,
              visible: _node.visibility,
            },
          })
          if (node.level === 2) {
            if (data.show) {
              this.$EventBus.$emit('select-mark-by-id', data.value)
            }
          }
        }
    },

    findNodeByLevel(node, level) {
      if (!node || node.level < level) {
        return null
      }
      if (node.level === level) {
        return node
      } else {
        return this.findNodeByLevel(node.parent, level)
      }
    },

    addNewLabel(val) {
      addLabel({
        elementType: val.type,
        gisJson: val.geojson,
        groupId: val.groupId,
        name: val.label,
      }).then((res) => {
        const newID = res.data.data.id;
        const keys = this.$refs.tree.getCheckedKeys();
        keys.push(newID);
        const params = {
          workSpaceId: this.$store.state.common.editProject.id,
          checkedKeys: keys,
        };
        setExpand(params).then((res) => {
          this.getList();
        });
      });
      this.$store.dispatch("setNewFeature", null); // 清空
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    download() {
      console.log("下载");
    },

    upload() {
      this.markDialogVisible = true
    },

    addFile() {
      this.showAppend = true;
      this.appendInput = `新建文件夹${this.groupCount + 1}`;
    },

    changeInput(n) {
      this.appendInput = n;
    },

    appendBlur() {
      let projectId = this.$route.query.projectId
      const data = {
        projectId,
        dirName: this.appendInput,
        dirType: 1
      };
      addTreeNode(data).then((res) => {
        if (res.data.code === 200) {
          this.getList();
          this.$EventBus.$emit('update-mark')
          this.showAppend = false;
        }
      });
    },

    check(a, b, c) {
      const params = {
        workSpaceId: this.$store.state.common.editProject.id,
        checkedKeys: b.checkedKeys,
      };

      setExpand(params).then((res) => {
        if (res.data.code === 200) {
          this.$nextTick(function () {
            this.$refs.tree.setCheckedKeys(b.checkedKeys);
          });
        }
      });
    },

    changeEdit(n) {
      this.newApiGroupName = n;
    },

    submitEdit(node, data) {
      if (data.label == this.newApiGroupName) {
        this.newApiGroupName = "";
        this.isEdit = 0;
        this.nodeItem = {};
      } else {
        this.updateApiGroup(data);
      }
    },

    updateApiGroup(data) {
      const params = {
        id: data.id,
        name: this.newApiGroupName,
        workSpaceId: this.$store.state.common.editProject.id,
      };
      addGroup(params).then((res) => {
        if (res.data.code === 200) {
          this.newApiGroupName = "";
          this.isEdit = 0;
          this.nodeItem = {};
          this.getList();
        }
      });
    },

    treeNodeMouseLeave(node, data) {
      this.deleteNodeValue = null
    },

    deleteTreeNode(node, data, e) {
      e.stopPropagation()
      if ( this.deleteNodeValue === null ) {
        this.deleteNodeValue = data.value
      } else {
        if (!data.hasOwnProperty('lockFlag')) {
          // 删除一个图元
          this.treeLoading = true
          this.$EventBus.$emit('delete-mark-by-id', data.value, ()=>{
            this.getList()
          })
        } else {
          // 删除路径
          deleteTreeNode({
            id: data.id,
          }).then(()=>{
            this.getList()
            this.$EventBus.$emit('update-mark')
          })
        }
        this.deleteNodeValue = null
      }
    },

    showHiddenNode(node, data, e) {
      e.stopPropagation()
      if (node.level === 1) {
        let flag = !this.isActive(data)
        let marks = []
        data.show = flag
        for (let i = 0; i < data.children.length; i++) {
          let item = data.children[i]
          item.show = flag
          marks.push(item)
        }
        updateDirVisibility({
          id: data.id,
          visibility: flag? 1: 0
        })
        this.$EventBus.$emit('visibility-mark-nodes', marks, flag, false)
      } else {
        let flag = !data.show
        data.show = flag
        this.$EventBus.$emit('visibility-mark-nodes', [data], flag, true)
        let _node = this.findNodeByLevel(node, 1)
        _node.data.show = this.isActive(_node.data)
      }
    },

    flyToNode(node, data, e){
      data.show = true
      e.stopPropagation()
      this.$EventBus.$emit('visibility-mark-nodes', data.value, true)
      window.setTimeout(() => {
        this.$EventBus.$emit('fly-to-by-id', data.value)
        this.$EventBus.$emit('select-mark-by-id', data.value)
      }, 200);
    },

    isActive(data){
      return data.children.some(item => {
        return item.show !== false
      })
    },

    handleClose() {
      this.markDialogVisible = false
      this.$refs.upload.clearFiles()
      this.$refs.form.resetFields()
    },

    uploadKML() {
      this.$refs.form.validate((res)=>{
        if (res) {
          this.loading = true
          this.$refs.upload.submit();
          setTimeout(()=>{
            this.handleClose()
          }, 0)
        }
      })
    },

    httpRequest(node) {
      let projectId = this.$route.query.projectId
      var formData = new FormData();
      formData.append('file', node.file)
      const url = `/api/aos-gisservice/mapmark/export`
      axios({
        baseURL: '',
        url: `${url}?projectId=${projectId}`,
        method: 'post',
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundaryn8D9asOnAnEU4Js0",
          'Authorization': `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`
        }
      }).then(res=>{
        const data = res.data
        if (data.code === 200) {
          this.getList()
          this.$EventBus.$emit('update-mark')
          this.$message.success(data.msg)
        } else {
          this.$message.error(data.msg)
        }
        this.loading = false
      }).catch(()=>{
        this.loading = false
      })
    },

    handleRemove(file) {
      console.log(file)
    },

    editNode(node, data, e) {
      e.stopPropagation()
      this.pathForm = {
        id: data.id,
        dirName: data.dirName
      }
      this.editDialogVisible = true
    },

    editClose() {
      this.$refs.pathForm.resetFields()
      this.editDialogVisible = false
    },

    saveEdit() {
      this.saveLoading = true
      updateTreeNode(this.pathForm).then(res=>{
        let data = res.data
        if (data.code === 200) {
          this.getList()
          this.$EventBus.$emit('update-mark')
          this.editClose()
          this.$message.success(data.msg)
        } else {
          this.$message.error(data.msg)
        }
      }).finally(()=>{
        this.saveLoading = false
      })
    }
  },
};
</script>

<style lang="scss">
  .mark-project-main-detail {
    display: flex;
    flex-direction: column;
    position: relative;
    .detail-title {
      position: absolute;
      top: -49px;
      left: -56px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #ffffff;
      padding-top: 9px;
      padding-bottom: 11px;
      display: flex;
      align-items: center;
      .icon-back {
        margin-right: 4px;
      }
    }
    .searchCon {
      .title {
        font-size: 14px;
        font-weight: bold;
        padding-top: 18px;
        color: #fff;
      }
      
      .btns {
        padding-top: 18px;
        text-align: right;
        .img {
          width: 17px;
          height: 14px;
          margin-left: 10px;
          cursor: pointer;
        }
      }

      .searchBtn {
        text-align: right;
        .el-button {
          border: #265edd;
          background: #265edd;
          height: 50%;
        }
      }

      .line {
        margin: 0;
        padding: 0;
        height: 1px;
        background-color: #030303;
      }

      .el-input__inner {
        background-color: #000000;
        border: 1px solid #535353;
        color: #FFFFFF;
      }
      
      input::-webkit-input-placeholder {
        -webkit-text-fill-color: #535353;
      }
    }
    .container {
      flex-grow: 1;
      .showAppend-input {
        margin-bottom: 4px;
      }
      margin-top: 12px;
      width: 100%;
      box-sizing: border-box;
      background-color: #1e222a;
      color: #eee;
      max-height: calc(100% - 140px);
      overflow-y: overlay;
      overflow-x: hidden;
      .line {
        margin: 0;
        padding: 0;
        height: 1px;
        background-color: #030303;
      }

      .tree-box {
        font-size: 14px;
        height: 100%;
        //一级节点不显示复选框 其他子节点，收起状态不显示复选框，展开状态显示复选框，叶子节点显示复选框

        .el-tree > .el-tree-node > .el-tree-node__content .el-checkbox {
          // display: none;
          margin-bottom: 2.5px;
        }
        .el-tree {
          background: transparent;
          color: #fff;
          .el-tree-node {
            //叶子节点显示复选框，其他节点不显示复选框
            .is-leaf ~ .el-checkbox .el-checkbox__inner {
              display: flex;
              margin-bottom: 2.5px;
            }
            .el-checkbox__inner {
              background: transparent;
              border: 1px solid #535353;
            }

            .el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
              background: #333333DD;
            }
          }
          .el-tree-node__content:hover {
            background-color: #333333DD;
            color: #fff;
          }
          
          .el-tree-node:focus>.el-tree-node__content {
            background: transparent;
            color: #fff;
          }

          .el-tree-node:focus {
            background: transparent !important;
            color: #fff;
          }

          .el-tree-node.is-current > .el-tree-node__content {
            background-color: #333333DD;
            color: #fff;

          }

          .el-table tbody tr {
            pointer-events: none;
          }
          .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
            color: #fff;
          }
          .el-button {
            color: #fff;
            font-size: 14px;
          }
        }
        .custom-tree-node {
          .el-input__inner {
            background-color: transparent;
            border: none;
            height: 100%;
            color: #fff;
          }
          width: 100%;
          .treeCon {
            width: 100%;
            .treeLeft {
              margin: 0;
            }
            .treeRight {
              margin: 0;
              text-align: end;
            }
          }
        }
        .tree-node {
          clear: both;
          flex-grow: 1;
          .tree-node-icon {
            float: left;
            margin-right: 5px;
          }
          .tree-node-text {
            float: left;
            max-width: 160px;
            flex-grow: 1;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 超出部分隐藏 */
            text-overflow: ellipsis; /* 显示省略号 */
          }
          .tree-node-btn {
            float: right;
            padding-right: 8px;
            i {
              margin-left: 4px;
            }
          }
        }
        .active-icon {
          color: #265edd;
        }
      }

      .append {
        .el-input__inner {
          background-color: transparent;
          border: none;
          height: 100%;
          color: #fff;
        }
      }
    }
    .bgc-red{
        background: red;
    }
    .bgc-red:hover{
        background: red !important;
    }
  }
  .private-mark-markpage-dialog {
    .el-input__inner {
      background-color: #000000 !important;
      border: 1px solid #535353 !important;
      color: #ffffff !important;
    }
    input::-webkit-input-placeholder {
      -webkit-text-fill-color: #535353 !important;
    }
  }
</style>
