var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-detail-view", style: "width: 290px" },
    [
      _c(
        "div",
        {
          staticClass: "detail-title",
          style: "left: " + (_vm.$route.query.action ? 0 : -56) + "px",
        },
        [
          _c("img", {
            staticClass: "icon icon-back",
            attrs: { src: "/img/project/icon-back.png", alt: "" },
            on: { click: _vm.backToList },
          }),
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.type === "add"
                  ? _vm.$t("addTask")
                  : _vm.type === "edit"
                  ? _vm.$t("editTask")
                  : _vm.title
              ) +
              "\n  "
          ),
        ]
      ),
      _vm.type != "add" && _vm.type != "edit"
        ? _c("div", { staticClass: "scroll-box-show" }, [_c("mark-page")], 1)
        : _c(
            "div",
            { staticClass: "scroll-box-edit" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "from-box",
                  attrs: { rules: _vm.rules, model: _vm.form },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "name-content",
                      attrs: {
                        prop: "name",
                        label: _vm.$t("emergency.taskName"),
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" + _vm.$t("inputPlease") },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "name-content",
                      attrs: { label: _vm.$t("emergency.taskDesc") },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          placeholder: "" + _vm.$t("inputPlease"),
                        },
                        model: {
                          value: _vm.form.taskDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "taskDesc", $$v)
                          },
                          expression: "form.taskDesc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "center" }, [
                    _c("div", { staticClass: "center-handle" }, [
                      _c("span", { staticClass: "center-handle-title title" }, [
                        _vm._v(_vm._s(_vm.$t("emergency.accidentLocation"))),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "center-handle-add",
                          on: { click: _vm.setProjectPoint },
                        },
                        [
                          _c("img", {
                            staticClass: "icon-location icon",
                            attrs: {
                              src: "/img/project/icon-location2.png",
                              alt: "",
                            },
                          }),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("emergency.drawLocation")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "center-detail" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("longitudeAndLatitude")) +
                          "： " +
                          _vm._s(_vm.getPoint()(_vm.form.eventLocation)) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "name-content",
                      attrs: { label: _vm.$t("emergency.team") },
                    },
                    [
                      _c("select-tree", {
                        staticStyle: { width: "100%" },
                        attrs: { multiple: true, data: _vm.ranksList },
                        model: {
                          value: _vm.form.ranksIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "ranksIds", $$v)
                          },
                          expression: "form.ranksIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "name-content",
                      attrs: { label: _vm.$t("emergency.dept") },
                    },
                    [
                      _c("select-tree", {
                        staticStyle: { width: "100%" },
                        attrs: { multiple: true, data: _vm.deptList },
                        model: {
                          value: _vm.form.deptIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "deptIds", $$v)
                          },
                          expression: "form.deptIds",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "name-content",
                      attrs: { label: _vm.$t("emergency.leader") },
                    },
                    [
                      _c("select-tree", {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.usersList },
                        model: {
                          value: _vm.form.leaderId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "leaderId", $$v)
                          },
                          expression: "form.leaderId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "name-content",
                      attrs: { prop: "eventType", label: _vm.$t("eventType") },
                    },
                    [
                      _c("select-tree", {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.eventTypeList },
                        model: {
                          value: _vm.form.eventType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "eventType", $$v)
                          },
                          expression: "form.eventType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "name-content",
                      attrs: { label: _vm.$t("eventLevel") },
                    },
                    [
                      _c("select-tree", {
                        staticStyle: { width: "100%" },
                        attrs: { data: _vm.eventLevelList },
                        model: {
                          value: _vm.form.eventLevel,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "eventLevel", $$v)
                          },
                          expression: "form.eventLevel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "name-content",
                      attrs: { label: _vm.$t("emergency.selectEquipment") },
                    },
                    [
                      _c("select-tree", {
                        staticStyle: { width: "100%" },
                        attrs: { multiple: true, data: _vm.equipmentList },
                        model: {
                          value: _vm.form.equipmentIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "equipmentIds", $$v)
                          },
                          expression: "form.equipmentIds",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "finish" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "finish-btn",
                      attrs: { loading: _vm.saveLoading, type: "primary" },
                      on: { click: _vm.handleFinish },
                    },
                    [_vm._v("\n        " + _vm._s(_vm.$t("save")) + "\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }