<template>
  <div class="project-detail-view" :style="`width: 290px`">
    <div
      :style="`left: ${$route.query.action? 0: -56}px`"
      class="detail-title">
      <img
        class="icon icon-back"
        @click="backToList"
        src="/img/project/icon-back.png"
        alt=""
      />
      {{ type === "add"  ? $t('addTask') : type ==="edit" ?  $t('editTask') : title }}
    </div>
    <!-- 展示项目 -->
    <div v-if="type != 'add' && type != 'edit'" class="scroll-box-show">
      <mark-page/>
    </div>
    <!-- 新增项目 -->
    <div v-else class="scroll-box-edit">
      <el-form 
        :rules="rules"
        class="from-box"
        ref="form"
        :model="form">
        <!-- 任务名称 -->
        <el-form-item prop="name"  class="name-content" :label="$t('emergency.taskName')">
          <el-input
            :placeholder="`${$t('inputPlease')}`"
            v-model="form.name">
          </el-input>
        </el-form-item>
        <!-- 任务描述 -->
        <el-form-item  class="name-content" :label="$t('emergency.taskDesc')">
          <el-input
            type="textarea"
            v-model="form.taskDesc"
            :placeholder="`${$t('inputPlease')}`">
        </el-input>
        </el-form-item>
        <!-- 项目中心点 -->
        <div class="center">
          <div class="center-handle">
            <span class="center-handle-title title">{{$t('emergency.accidentLocation')}}</span>
            <span class="center-handle-add"  @click="setProjectPoint">
              <img class="icon-location icon" src="/img/project/icon-location2.png" alt="" />
              {{$t('emergency.drawLocation')}}
            </span>
          </div>
          <div class="center-detail">
            {{$t('longitudeAndLatitude')}}： {{ getPoint()(form.eventLocation) }}
          </div>
        </div>
        <!-- 应急队伍 -->
        <el-form-item  class="name-content" :label="$t('emergency.team')">
          <select-tree
              style="width: 100%"
              :multiple="true"
              :data="ranksList"
              v-model="form.ranksIds"/>
        </el-form-item>
        <!-- 所属团队 -->
        <el-form-item  class="name-content" :label="$t('emergency.dept')">
          <select-tree
              style="width: 100%"
              :multiple="true"
              :data="deptList"
              v-model="form.deptIds"/>
        </el-form-item>
        <!-- 项目负责人 -->
        <el-form-item  class="name-content" :label="$t('emergency.leader')">
          <select-tree
              style="width: 100%"
              :data="usersList"
              v-model="form.leaderId"/>
        </el-form-item>
        <!-- 事件类型 -->
        <el-form-item prop="eventType"  class="name-content" :label="$t('eventType')">
          <select-tree
              style="width: 100%"
              :data="eventTypeList"
              v-model="form.eventType"/>
        </el-form-item>
        <!-- 事件等级 -->
        <el-form-item  class="name-content" :label="$t('eventLevel')">
          <select-tree
              style="width: 100%"
              :data="eventLevelList"
              v-model="form.eventLevel"/>
        </el-form-item>
        <!-- 选择设备 -->
        <el-form-item  class="name-content" :label="$t('emergency.selectEquipment')">
          <select-tree
              style="width: 100%"
              :multiple="true"
              :data="equipmentList"
              v-model="form.equipmentIds"/>
        </el-form-item>
      </el-form>
      <div class="finish">
        <el-button
          :loading="saveLoading"
          class="finish-btn"
          type="primary"
          @click="handleFinish">
          {{$t('save')}}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import markPage from "./markPage.vue"
import {
  updateEmergencytask,
  getEmergencytaskDetail
} from "@/api/emergency/index";
import { getDeptTree } from '@/api/system/dept'
import { getEmergencyranksList } from "@/api/system/emergencyranks";
import { getDictionary } from "@/api/system/dictbiz";
import { getEquipList } from "@/api/phugiacloud/equipmentManagement";
import { getuserList } from "@/api/system/user";
import { mapGetters } from "vuex";

export default {
  name: "	ET_detail",
  components: {markPage},
  data() {
    return {
      detailInfo: {},
      type: 'detail',
      // 表单
      form: {
        name: '',
        taskDesc: '',
        eventLocation:'',
        ranksIds: [],
        deptIds: [],
        leaderId: '',
        eventType: '',
        eventLevel: '',
        equipmentIds: []
      },
      rules: {
          name: [
            { required: true, message: this.$t('inputPlease'), trigger: 'blur' },
          ],
          eventType: [
            { required: true, message: this.$t('selectPlease'), trigger: 'blur' },
          ]
      },
      // 设备列表
      equipmentList: [],
      // 事件类型列表
      eventTypeList: [],
      // 事件等级列表
      eventLevelList: [],
      // 应急队伍列表
      ranksList: [],
      // 所属部门列表
      deptList: [],
      // 人员名单：
      usersList: [],
      // loading等待
      saveLoading: false,
      
    };
  },
  watch: {
    detailInfo: {
      handler(value) {
        this.form = value;
      },
      deep: true,
    },
    projectPoint(value) {
      this.form.eventLocation = value.join(',')
    }
  },
  computed: {
    title() {
      if (this.$route.query &&  this.$route.query.name) {
        return this.$route.query.name
      }
      if (this.detailInfo && this.detailInfo.name) {
        return this.detailInfo.name
      }
      return ''
    },
    ...mapGetters(["projectPoint"]),
  },
  created() {
    const projectId = this.$route.query.projectId
    this.type = this.$route.query.action? this.$route.query.action: 'detail'
    if (this.type !== 'add' && projectId) {
      getEmergencytaskDetail({id: projectId}).then((res) => {
        if (res.data.code === 200) {
          this.detailInfo = res.data.data;
        }
      });
    }
    if (this.type !== 'detail') {
      this.loadDeptList()
      this.loadEmergencyranks()
      this.loadEvenTypeList()
      this.loadEventLevelList()
      this.loadEquipmentList()
      this.loadUserList()
    }
  },
  methods: {
    // 回退
    backToList() {
      this.$router.push('/emergency/emergency-task');
    },
    // 加载部门
    loadDeptList() {
      getDeptTree().then(res=>{
        if (res.data.code === 200) {
          this.deptList = this.filterDeptTree(res.data.data)
        }
      })
    },
    // 应急队伍
    loadEmergencyranks() {
      getEmergencyranksList(1, 9999).then(res=>{
        if (res.data.code === 200) {
          this.ranksList = res.data.data.records.map(item=>{
            return {
              value: item.id,
              label: item.name
            }
          })
        }
      })
    },
    // 事件类型
    loadEvenTypeList() {
      getDictionary({
        code: 'EmergencyEventType'
      }).then(res=>{
        if (res.data.code === 200) {
          this.eventTypeList = res.data.data.map(item=>{
            return {
              value: item.dictKey,
              label: item.dictValue
            }
          })
        }
      })
    },
    // 事件等级
    loadEventLevelList() {
      getDictionary({
        code: 'EmergencyEventLevel'
      }).then(res=>{
        if (res.data.code === 200) {
          this.eventLevelList = res.data.data.map(item=>{
            return {
              value: item.dictKey,
              label: item.dictValue
            }
          })
        }
      })
    },
    // 设备列表
    loadEquipmentList() {
      getEquipList(1, 9999).then(res=>{
        if (res.data.code === 200) {
          this.equipmentList = res.data.data.records.map(item=>{
            return {
              value: item.id,
              label: item.equipmentName
            }
          })
        }
      })
    },
    //loadUserList
    loadUserList() {
      getuserList(1, 9999).then(res=>{
        if (res.data.code === 200) {
          this.usersList = res.data.data.records.map(item=>{
            return {
              value: item.id,
              label: item.realName
            }
          })
        }
      })
    },
    // 保存
    handleFinish() {
      this.$refs.form.validate((res)=>{
        if (res) {
          this.saveLoading = true
          let data = JSON.parse(JSON.stringify(this.form))
          data.ranksNames = this.ranksList.filter(item=>{
            return this.form.ranksIds.indexOf(item.value) > -1
          }).map(item=>{
            return item.label
          }).join(',')
          data.leaderName  = this.usersList.filter(item=>{
            return this.form.leaderId === item.value
          }).map(item=>{
            return item.label
          }).join(',')
          data.equipmentNames  = this.equipmentList.filter(item=>{
            return this.form.equipmentIds.indexOf(item.value) > -1
          }).map(item=>{
            return item.label
          }).join(',')
          updateEmergencytask(data).then((res) => {
            if (res.data.code === 200) {
              this.$message.success(res.data.msg);
              this.$router.push(this.$route.matched[1].path);
              this.$store.dispatch("setProjectPoint", null);
              this.$store.dispatch("setRouteVisible", false);
            }
          }).catch((err) => {
            this.$message.error(err.msg);
          }).finally(()=>{
            this.saveLoading = false;
          })
        }
      })
    },
    // 中心点展示
    getPoint() {
      return function (location) {
        if (typeof location !== 'string') return
        let arr = location.split(',')
        let point = "";
        if (arr && arr.length > 1) {
          const lon = parseFloat(arr[0]);
          const lat = parseFloat(arr[1]);
          if (lon >= 0) {
            point += lon.toFixed(7) + " E,";
          } else {
            point += lon.toFixed(7) + " W,";
          }
          if (lat >= 0) {
            point += lat.toFixed(7) + " N";
          } else {
            point += lat.toFixed(7) + "S";
          }
        }
        return point;
      };
    },
    // 设置中心点
    setProjectPoint() {
      this.$store.dispatch("setHandlerType", { 
        type: "projectPoint",
        params: this.form
      });
    },
    // 多虑数据
    filterDeptTree(tree) {
      return tree.map((item) => {
        item.label = item.title;
        item.value = item.id;
        if (item.children && Array.isArray(item.children)) {
          item.children = this.filterDeptTree(item.children);
        }
        return item;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.scroll-box-show {
  font-family: MicrosoftYaHeiUI;
  overflow: auto;
  color: #FFFFFF;
  height: 100%;
  .progress-box {
    padding: 14px 16px 28px 16px;
    .progress-title{
      height: 21px;
      font-size: 14px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 21px;
      margin-bottom: 22px;
    }
    .progress-line{
      display: flex;
      margin-top: 15px;
      &-label{
        height: 15px;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 15px;
        padding-right: 4px;
      }
      :last-child{
        flex-grow: 1;
      }
      /deep/.el-progress--line{
        display: flex;
        align-items: center;
        .el-progress-bar {
          flex-grow: 1;
        }
        .el-progress__text {
          color: #FFFFFF;
        }
      }
    }
  }
  .show-content{
    display: flex;
    &-left{
      width: 50%;
    }
    &-right{
      width: 50%;
    }
    .show-content-title {
      background-color: #030303;
      height: 32px;
      font-size: 14px;
      font-weight: bold;
      line-height: 32px;
      padding-left: 16px;
    }
    .show-content-value {
      height: 45px;
      font-size: 16px;
      line-height: 32px;
      padding-left: 16px;
    }
  }
}
.project-detail-view {
  position: relative;
  height: 100%;
  width: 100%;
  .detail-title {
    position: absolute;
    top: -49px;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
    font-weight: bold;
    color: #ffffff;
    padding-top: 9px;
    padding-bottom: 11px;
    display: flex;
    align-items: center;
    .icon-back {
      margin-right: 4px;
    }
  }
  .scroll-box-edit {
    height: calc(100%);
    .from-box{
      overflow: auto;
      height: calc(100% - 59px);
      .name-content {
        margin: 8px 18px;
        font-size: 14px;
        font-family: MicrosoftYaHeiUI;
        color: #ffffff;
        line-height: 18px;
        /deep/.el-form-item__label{
          padding: 10px 5px 5px 0px;
          font-size: 12px;
          font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
          font-weight: bold;
          color: #ffffff;
          line-height: 18px;
          display: flex;
          justify-content: space-between;
        }
        /deep/.el-input{
          background: #030303;
        }
        /deep/.el-input__inner {
          border: none;
          color: #ffffff;
          text-align: left;
          background: transparent;
          width: 265px;
        }
        /deep/.el-textarea__inner{
          border: none;
          background: #030303;
          color: #ffffff;
        }
      }
      .from-name {
        .name-title {
          padding: 10px 5px 5px 17px;
          font-size: 12px;
          font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
          font-weight: bold;
          color: #ffffff;
          line-height: 18px;
          display: flex;
          justify-content: space-between;
          &-id {
            color: #76787d;
            font-size: 12px;
            font-weight: normal;
          }
        }
      }
    }
  }
  .finish {
    &-btn {
      width: 267px;
      height: 36px;
      line-height: 36px;
      background: #265edd;
      border-radius: 3px;
      font-size: 14px;
      font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
      font-weight: bold;
      color: #ffffff;
      padding: 0;
      border: none;
      margin-top: 10px;
      margin-left: 10px;
    }
  }
  .icon {
    cursor: pointer;

    &-edit {
      height: 13px;
    }

    &-delete {
      margin-left: 18px;
      height: 13px;
    }

    &-add {
      margin-left: 4px;
      height: 10px;
    }

    &-location {
      height: 18px;
      margin-right: 4px;
    }
  }
  .center {
    padding: 15px 15px;
    border-bottom: 1px solid #030303;
    .center-handle {
      padding: 5px 0px;
      display: flex;
      justify-content: space-between;
      &-title {
        color: #FFFFFF;
        font-size: 14px;
        font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;
        font-weight: bold;
      }
      &-add {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
        font-family: MicrosoftYaHeiUI;
        color: #4981ff;
      }
    }
    &-detail {
      font-size: 12px;
      font-family: MicrosoftYaHeiUI;
      color: #999999;
    }
  }
}
.required {
  color: red;
  margin-right: 5px;
}
</style>
